import { useState, useEffect } from 'react';
import { shippingService } from '../shipping/services/shipping';
import { useQuasmWalletContext } from '@quasm/quasm-wallet-react';


export default function useMultiModalContract({ fetchShippings = false, detail = false, shippingId = null } = {}) {

    const { sessionAccount } = useQuasmWalletContext()
    const [ shippings, setShippings ] = useState([]);
    const [ fetchShippingsError, setFetchShippingsError] = useState(false);
    const [ shipping, setShipping ] = useState({events: []});
    const [ isLoading, setIsLoading ] = useState(false);

    useEffect(() => {   
        if (fetchShippings) {
            getShippings();
        }
        if (detail && shippingId) {
            getShippingDetail();
        }
    }, [fetchShippings, detail, shippingId]);

    async function getShippings(){
        setIsLoading(true)
        try{
            let shippingData = await shippingService.getAll()
            setShippings(shippingData);
        } catch (e){
            setFetchShippingsError(true)
        } finally {
            setIsLoading(false)
        }
    }

    async function getShippingDetail(){
        setIsLoading(true)
        try {
            let shippingDetail = await shippingService.retrieveShipping(shippingId);
            let shippingCreatedEvent = await shippingService.getShippingCreatedEvent(shippingId);
            let shippingEvents = await shippingService.getAllShippingEvents(shippingId);
            shippingDetail.transaction_hash = shippingCreatedEvent.transaction_hash;
            shippingDetail.blockLink = `${shippingService.blockExplorerUrl}/blocks/${shippingCreatedEvent.block_number}`;
            shippingDetail.events = shippingEvents;
            setShipping(shippingDetail)
        } catch (e){
            console.log(e)
        } finally {
            setIsLoading(false)
        }
        
    }

    async function createShipping( containerName, contents, shipper, receiver, origin, destination){
        const timestamp = Math.floor(Date.now() / 1000);  
        const createdShippingId = await shippingService.create(
            sessionAccount, 
            containerName,
            contents, 
            shipper, 
            receiver, 
            origin, 
            destination, 
            timestamp
        )
        return createdShippingId;
    }

    async function createShippingEvent(description){
        const timestamp = Math.floor(Date.now() / 1000);  
        const response = await shippingService.createShippingEvent(sessionAccount, shippingId, description, timestamp)
        console.log('Transaction sent:', response.transaction_hash);
        getShippingDetail()
    }

    async function validateReceipt(){
        const timestamp = Math.floor(Date.now() / 1000);  
        const response = await shippingService.validateReceipt(sessionAccount, shippingId, timestamp)
        console.log('Transaction sent:', response.transaction_hash);
        getShippingDetail()
    }

    return {
      createShipping,
      createShippingEvent,
      validateReceipt,
      shippings,
      fetchShippingsError,
      shipping,
      isLoading
    }

}