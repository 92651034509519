import React from 'react';

const DataIntegrityAlert = ({ anomaliesDetected }) => {
  return (
    <div className="p-4 rounded shadow bg-green-50">
      {anomaliesDetected === 0 ? (
        <div>
          <h3 className="text-lg font-bold text-green-600">All Data Verified</h3>
          <p className="text-gray-600">No anomalies or discrepancies detected in the shipping data.</p>
        </div>
      ) : (
        <div>
          <h3 className="text-lg font-bold text-red-600">Data Anomalies Detected</h3>
          <p className="text-gray-600">
            {anomaliesDetected} discrepancies found. Please review the affected shipments.
          </p>
        </div>
      )}
    </div>
  );
};

export default DataIntegrityAlert;
