import React from 'react';
import Map from '../components/Map';
import MetricCard from '../components/MetricCard';
import DataIntegrityAlert from '../components/DataIntegrityAlert';
import HistoricalTrends from '../components/HistoricalTrends';
import CostSavingsWidget from '../components/CostSavingsWidget';
import ROICalculator from '../components/ROICalculator';
import AnomalySavings from '../components/AnomalySavings';
import EfficiencyMetrics from '../components/EfficiencyMetrics';
import FraudFreeBadge from '../components/FraudFreeBadge';

const MetricsDashboard = () => {
  const metrics = {
    totalContainers: 120,
    inTransit: 85,
    delayed: 5,
    delivered: 30,
    anomaliesDetected: 0, // Highlight: No anomalies, thanks to data integrity.
  };

  const savingsData = {
    totalSavings: 24000, // Total savings this month in dollars
    anomaliesDetected: 0,
    costPerAnomaly: 500, // Average cost saved per anomaly detected
    fraudLossBefore: 100000, // Fraud loss per year before using this system
    fraudLossAfter: 5000, // Fraud loss per year after using this system
    systemCost: 15000, // Annual system cost
    deliveryTimeBefore: 5, // Average delivery time in days before system
    deliveryTimeAfter: 3.2, // Average delivery time in days now
    latePenaltySavings: 8000, // Savings from reduced late penalties
    fraudFreeShipments: 120, // Total shipments verified fraud-free
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Header */}
      <header className="bg-white shadow p-4">
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-2xl font-bold text-gray-800">Shipping Tracker Dashboard</h1>
          <button className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700">
            Export Report
          </button>
        </div>
      </header>

      {/* Main Content */}
      <main className="container mx-auto mt-6 grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Real-Time Map */}
        <section className="col-span-2 bg-white shadow rounded-lg p-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">Real-Time Container Map</h2>
          <Map />
        </section>

        {/* Key Metrics */}
        <section className="bg-white shadow rounded-lg p-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">Key Metrics</h2>
          <div className="grid grid-cols-2 gap-4">
            <MetricCard label="Total Containers" value={metrics.totalContainers} />
            <MetricCard label="In Transit" value={metrics.inTransit} color="blue" />
            <MetricCard label="Delayed" value={metrics.delayed} color="red" />
            <MetricCard label="Delivered" value={metrics.delivered} color="green" />
          </div>
        </section>

        {/* Fraud-Free Badge */}
        <section className="col-span-1 lg:col-span-3 bg-white shadow rounded-lg p-6">
          <FraudFreeBadge fraudFreeShipments={savingsData.fraudFreeShipments} />
        </section>

        {/* Data Integrity Alert */}
        <section className="col-span-1 lg:col-span-3 bg-white shadow rounded-lg p-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">Data Integrity Status</h2>
          <DataIntegrityAlert anomaliesDetected={metrics.anomaliesDetected} />
        </section>

        {/* Cost Savings */}
        <section className="bg-white shadow rounded-lg p-6">
          <CostSavingsWidget savings={savingsData.totalSavings} />
        </section>

        {/* ROI Analysis */}
        <section className="bg-white shadow rounded-lg p-6">
          <ROICalculator
            fraudLossBefore={savingsData.fraudLossBefore}
            fraudLossAfter={savingsData.fraudLossAfter}
            systemCost={savingsData.systemCost}
          />
        </section>

        {/* Anomaly Savings */}
        <section className="bg-white shadow rounded-lg p-6">
          <AnomalySavings
            anomaliesDetected={savingsData.anomaliesDetected}
            costPerAnomaly={savingsData.costPerAnomaly}
          />
        </section>

        {/* Efficiency Metrics */}
        <section className="bg-white shadow rounded-lg p-6">
          <EfficiencyMetrics
            deliveryTimeBefore={savingsData.deliveryTimeBefore}
            deliveryTimeAfter={savingsData.deliveryTimeAfter}
            latePenaltySavings={savingsData.latePenaltySavings}
          />
        </section>

        {/* Historical Trends */}
        <section className="col-span-1 lg:col-span-3 bg-white shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold text-gray-700 mb-4">Historical Trends</h2>
            <HistoricalTrends />
        </section>
      </main>
    </div>
  );
};

export default MetricsDashboard;
