// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from './commons/Navigation';
import NewShippingPage from './shipping/pages/NewShipping';
import ShipmentsPage from './shipping/pages/Shipments';
import ShippingDetail from './shipping/pages/ShippingDetail';
import { Login, useQuasmWalletContext } from "@quasm/quasm-wallet-react"
import MetricsDashboard from './shipping/pages/Metrics';


const App = () => {

  const { sessionAccount } = useQuasmWalletContext()

  return (
    <Router>
      <div className="min-h-screen bg-background text-textPrimary">
        {
          sessionAccount ?
          <>
            <Navigation sessionAccount={sessionAccount}/>
            <main className="max-w-7xl mx-auto">
              <Routes>
                <Route path="/" element={<ShipmentsPage />} />
                <Route path="/new-shipping" element={<NewShippingPage />} />
                <Route path="/shippings/:id" element={<ShippingDetail />} />
                <Route path="/metrics" element={<MetricsDashboard />} />
              </Routes>
            </main>
          </>
        :
          <Login
            placeholderText={{ username: 'Enter your username', password: 'Enter your password' }}
            buttonText="Sign In"
          />
        }
      </div>
    </Router>
  );
};

export default App;
