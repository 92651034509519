const FraudFreeBadge = ({ fraudFreeShipments }) => {
    return (
      <div className="bg-teal-50 p-4 rounded shadow flex items-center">
        <div className="mr-4">
          <span className="bg-teal-500 text-white p-2 rounded-full text-2xl font-bold">
            {fraudFreeShipments}
          </span>
        </div>
        <div>
          <h2 className="text-xl font-semibold text-teal-600">Fraud-Free Shipments</h2>
          <p className="text-gray-600">All shipments verified with 100% data integrity.</p>
        </div>
      </div>
    );
  };

export default FraudFreeBadge;