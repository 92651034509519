import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOXGL_API_KEY;

const Map = () => {
  const mapContainerRef = useRef(null);

  const containers = [
    { id: 'C001', location: [121.5, 31.2], status: 'In Transit', name: 'Port of Shanghai, China' }, // Shanghai
    { id: 'C002', location: [139.7, 35.6], status: 'Delivered', name: 'Port of Tokyo, Japan' }, // Tokyo
    { id: 'C003', location: [106.9, -6.1], status: 'Delayed', name: 'Port of Jakarta, Indonesia' }, // Jakarta
    { id: 'C004', location: [126.6, 37.5], status: 'In Transit', name: 'Port of Busan, South Korea' }, // Busan
    { id: 'C005', location: [103.9, 1.3], status: 'In Transit', name: 'Port of Singapore, Singapore' }, // Singapore
  ];

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [113, 22],
      zoom: 4,
    });

    containers.forEach((container) => {
      const marker = new mapboxgl.Marker({ color: container.status === 'Delayed' ? 'red' : 'blue' })
        .setLngLat(container.location)
        .setPopup(
          new mapboxgl.Popup({ offset: 25, maxWidth: '250px' }) // Limit popup width
            .setHTML(
              `<h3>${container.name}</h3><p>Status: ${container.status}</p><p>Container ID: ${container.id}</p>`
            )
        )
        .addTo(map);
    });

    // Cleanup on component unmount
    return () => map.remove();
  }, [containers]);

  return (
    <div
      ref={mapContainerRef}
      className="h-96 w-full rounded shadow-lg overflow-hidden" // Ensure overflow-hidden is set
    ></div>
  );
};

export default Map;
