const AnomalySavings = ({ anomaliesDetected, costPerAnomaly }) => {
    const totalSavings = anomaliesDetected * costPerAnomaly;
  
    return (
      <div className="bg-yellow-50 p-4 rounded shadow">
        <h2 className="text-xl font-semibold text-yellow-600">Savings from Anomaly Prevention</h2>
        <p className="text-gray-600">
          Detected <span className="font-bold">{anomaliesDetected}</span> anomalies, saving 
          <span className="font-bold"> ${totalSavings.toLocaleString()}</span> this month!
        </p>
      </div>
    );
  };

export default AnomalySavings;