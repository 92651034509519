import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { QuasmWalletProvider } from "@quasm/quasm-wallet-react"

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
      <QuasmWalletProvider
        apiKey={"7T5GCOLE32KURFma2ipuqpCQqeC5opnA"}
      >
        <App />
      </QuasmWalletProvider>
  </React.StrictMode>
);

