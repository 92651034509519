const EfficiencyMetrics = ({ deliveryTimeBefore, deliveryTimeAfter, latePenaltySavings }) => {
    const improvement = deliveryTimeBefore - deliveryTimeAfter;
  
    return (
      <div className="bg-indigo-50 p-4 rounded shadow">
        <h2 className="text-xl font-semibold text-indigo-600">Operational Efficiency</h2>
        <p className="text-gray-600">
          Average delivery time improved by <span className="font-bold">{improvement} days</span>.
        </p>
        <p className="text-gray-600">
          Late penalties reduced by <span className="font-bold">${latePenaltySavings.toLocaleString()}</span>.
        </p>
      </div>
    );
  };
  
  export default EfficiencyMetrics;