import React from 'react';

const MetricCard = ({ label, value, color = 'gray' }) => {
  const colorClasses = {
    gray: 'text-gray-800',
    blue: 'text-blue-600',
    red: 'text-red-600',
    green: 'text-green-600',
  };

  return (
    <div className="bg-blue-50 p-4 rounded shadow">
      <p className="text-gray-500">{label}</p>
      <h3 className={`text-2xl font-bold ${colorClasses[color]}`}>{value}</h3>
    </div>
  );
};

export default MetricCard;
