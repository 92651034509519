import React from 'react';

const CostSavingsWidget = ({ savings }) => {
  return (
    <div className="bg-green-50 p-4 rounded shadow">
      <h2 className="text-xl font-semibold text-green-600 mb-2">Cost Savings</h2>
      <p className="text-gray-600">
        Your system has saved you <span className="text-3xl font-bold">${savings.toLocaleString()}</span> this month!
      </p>
    </div>
  );
};

export default CostSavingsWidget;
