const ROICalculator = ({ fraudLossBefore, fraudLossAfter, systemCost }) => {
    const annualSavings = fraudLossBefore - fraudLossAfter - systemCost;
  
    return (
      <div className="bg-blue-50 p-4 rounded shadow">
        <h2 className="text-xl font-semibold text-blue-600">ROI Analysis</h2>
        <p className="text-gray-600">
          Annual Savings: <span className="text-2xl font-bold">${annualSavings.toLocaleString()}</span>
        </p>
        <p className="text-gray-600">Fraud Loss Before: ${fraudLossBefore.toLocaleString()}</p>
        <p className="text-gray-600">Fraud Loss Now: ${fraudLossAfter.toLocaleString()}</p>
        <p className="text-gray-600">System Cost: ${systemCost.toLocaleString()}</p>
      </div>
    );
  };
  
export default ROICalculator